jQuery(document).ready(function ($) {
  const imgSwiperOne = new Swiper('.swiper', {
    slidesPerView: 'auto',
    spaceBetween: 40,
    centeredSlides: true,
    grabcursor: true,
    loop: true,
    preloadImages: true,
    breakpoints: {
      300: {
        slidesPerView: 1.5,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1920: {
        slidesPerView: 5,
        spaceBetween: 50,
      }
    }
  });
  // формируем заголовок с тенью-обводкой
  const titleElement = document.querySelector('.hero__title');
  // Функция для создания span-элементов
  function createTitleSpans(text) {
    // Очищаем существующее содержимое заголовка
    titleElement.innerHTML = '';
    const words = text.split(' ');
    words.forEach((word, wordIndex) => {
      const wordSpan = document.createElement('span');
      wordSpan.classList.add('word');
      wordSpan.textContent = word;
      // Создаем дополнительный span-элемент для эффекта обводки
      const shadowSpan = document.createElement('span');
      shadowSpan.classList.add('word-shadow');
      shadowSpan.textContent = word;
      wordSpan.appendChild(shadowSpan);
      titleElement.appendChild(wordSpan);
      // Добавляем пробел после каждого слова, кроме последнего
      if (wordIndex !== words.length - 1) {
        const spaceSpan = document.createElement('span');
        spaceSpan.textContent = ' ';
        titleElement.appendChild(spaceSpan);
      }
    });
  }
  // Вызываем функцию для создания span-элементов
  createTitleSpans(titleElement.textContent);

  // мобильное меню
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.menu__container--mobile').length && !$(e.target).hasClass('menu__btn')) {
      // Если клик произошел вне меню и не на кнопке открытия меню
      $('.menu__container--mobile').removeClass('visible');
    }
  });
  $(".menu__btn").on("click", function (e) {
    e.stopPropagation(); // Предотвращаем закрытие меню при клике на саму кнопку
    showMenu();
  });
  function showMenu() {
    $('.menu__container--mobile').toggleClass('visible');
  }
  // фиксация меню
  var menu = document.getElementById('menu');
  var burger = document.getElementById('burger');
  var lastScrollPosition = 0;
  window.addEventListener('scroll', function () {
    var currentScrollPosition = window.scrollY;
    if (currentScrollPosition > lastScrollPosition && currentScrollPosition >= burger.offsetTop) {
      menu.classList.add('sticky');
    } else if (currentScrollPosition < lastScrollPosition && currentScrollPosition < burger.offsetTop) {
      menu.classList.remove('sticky');
    }
    lastScrollPosition = currentScrollPosition;
  });
  // подсветка активного блока в якорном меню
  // var menuItems = document.querySelectorAll('.menu__item, .menu-item');
  var menuItems = document.querySelectorAll('.menu__container .menu-item');
  var sections = document.querySelectorAll('section');
  var lastActiveMenuItem = null;
  // Устанавливаем активный класс на первый элемент меню
  menuItems[0]?.classList.add('active');
  lastActiveMenuItem = menuItems[0];
  window.addEventListener('scroll', function () {
    var currentScrollPosition = window.scrollY;
    for (var i = 0; i < sections.length; i++) {
      var section = sections[i];
      var sectionTop = section.offsetTop;
      var sectionHeight = section.offsetHeight;

      if (currentScrollPosition >= sectionTop && currentScrollPosition < sectionTop + sectionHeight) {
        // var activeMenuItem = document.querySelector('.menu__item a[href="#' + section.id + '"], .menu-item a[href="#' + section.id + '"]')?.parentElement;
        var activeMenuItem = document.querySelector('.menu__container .menu-item a[href="#' + section.id + '"]')?.parentElement;
        if (activeMenuItem && activeMenuItem !== lastActiveMenuItem) {
          if (lastActiveMenuItem) {
            lastActiveMenuItem.classList.remove('active');
          }
          activeMenuItem.classList.add('active');
          lastActiveMenuItem = activeMenuItem;
        }
        break;
      }
    }
  });
  // modals
  const modal = document.getElementById("modal");
  const closeButton = document.getElementsByClassName("close-button")[0];
  const openModalButton = document.querySelector(".open-modal");

  // Открытие модального окна
  openModalButton.addEventListener("click", () => {
    modal.style.display = "block";
  });
  // Закрытие модального окна
  closeButton.addEventListener("click", () => {
    modal.style.display = "none";
  });
  window.addEventListener("click", (event) => {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  });

  function createMap(mapElement, center, zoom, iconUrl) {
    ymaps.ready(function () {
      var map = new ymaps.Map(mapElement, {
        center: center,
        zoom: zoom,
        controls: [],
      });
      map.controls
        .add('zoomControl', { top: 5, left: 5 })
        .add('rulerControl', { top: 50, left: 5 });

      var myGeoObject = new ymaps.GeoObject({
        geometry: {
          type: "Point",
          coordinates: [60.972852, 69.024220]
        },
        properties: {
          iconContent: 'Моя метка',
          hintContent: 'Я тоже съезжаю, но только притворяюсь'
        }
      }, {
        preset: 'islands#blackStretchyIcon',
        draggable: true,
        iconLayout: 'default#image',
        iconImageHref: iconUrl,
        iconImageSize: [98, 112],
        iconImageOffset: [-49, -112]
      });
      map.geoObjects.add(myGeoObject);

      map.events.add('wheel', function (e) {
        e.preventDefault();
      });
    });
  }
  // Десктопная версия
  createMap('map', [60.973267, 69.016421], 16, 'img/map.svg');
  // Мобильная версия
  createMap('mobile-map', [60.987598, 69.025508], 14, 'img/map.svg');

  // Отображение/скрытие кнопки "Scroll to Top"
  window.onscroll = function () {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("scrollToTopBtn").style.display = "block";
    } else {
      document.getElementById("scrollToTopBtn").style.display = "none";
    }
  };
  // Функция плавной прокрутки к верху страницы
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
});